/* You can add global styles to this file, and also import other style files */

.error-input{
    border: 1px solid #ec3434 !important;
    background-color: #f5e7e7;
    transition: background-color  1s;
    .ng-select-container {
        background-color: #f5e7e7;
        transition: background-color  1s;
    }
}
// regular style toast
@import 'ngx-toastr/toastr';
